import React from "react";

const PageLayout = ({ children }) => {
  return (
    <main className={"p-3 xs:p-4  sm:p-8 lg:px-20 xl:px-24 2xl:px-64 3xl:px-96"}>
      {children}
    </main>
  );
};

export default PageLayout;
