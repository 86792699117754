import React, {createRef, useEffect, useState} from "react";
import lottie from "lottie-web";
import ScrollTrigger from "react-scroll-trigger";

const Animation = ({data, delay, styles, noExit}) => {

  const [play, setPlay] = useState(!!noExit);
  const animationContainer = createRef();
  useEffect(() => {
    const anim = lottie.loadAnimation({
      container: animationContainer.current,
      renderer: "svg",
      loop: false,
      autoplay: false,
      animationData: data,
    });
    const timer = setTimeout(() => {
      play && anim.play();
    }, delay);

    return () => {
      clearTimeout(timer);
      anim.destroy();
    };
  }, [play, animationContainer, delay, data]);
  return (
    <ScrollTrigger
      onEnter={() => {
        setPlay(true);
      }}
      onExit={() => {
        !noExit && setPlay(false);
      }}
    >
      <div
        id="animation-container"
        className={`${styles}`}
        ref={animationContainer}
      />
    </ScrollTrigger>
  );
};

export default Animation;
